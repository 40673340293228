import { BasePageTemplate, TagManager } from '@accor/ace-ui-core';

export default class LpActivityEvent extends BasePageTemplate {
  static CLASS_NAMESPACE = 'brand-corner-detail-page-template';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.lpActivityEventClass = this.componentHost.classList.contains(`${BasePageTemplate.CLASS_NAMESPACE}`);
    this.pageName = this.componentHost?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');

    if (this.lpActivityEventClass) {
      this.lpActivityEventDetail();
    }
  }

  /*
      Function to tagmanager for brandCorner
    */
  lpActivityEventDetail() {
    let dataLayerJson = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerJson) {
      dataLayerJson = JSON.parse(dataLayerJson);
    }
    const thematicPage = dataLayerJson.pageName.split('::')[0];

    if(thematicPage==="lp-europcar") return ; //Fix to avoid duplicate events for europcar

    const socialMediaBlock = () => {
      const lastIndex = dataLayerJson.pageName.lastIndexOf('::');
      const pagename = dataLayerJson.pageName.substring(0, lastIndex);
      const brandName = window.location.pathname?.split('/')?.filter(Boolean).pop()?.split('.html')[0];

      const socialMediaButton = this.componentHost.querySelectorAll('.ace-icon-list-component .ace-footer-icon__list--item--link');
      socialMediaButton?.forEach((smb) => {
        const socialName = smb.getAttribute('aria-label').trim().split(' ')[0];
        const url = smb.getAttribute('href');

        smb?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          event.preventDefault();

          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: `${pagename || ''}::${brandName || ''}`,
              bloc_name: 'social media',
              bloc_interaction: 'follow',
              bloc_type: `${socialName || ''}`
            }
          };
          window.dataLayer.push({
            'event_data': null
          });
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);

          window.open(url, '_blank');
        });
      });
    };
    socialMediaBlock();
    const highLightBlock = () => {
      const lastIndex = dataLayerJson.pageName.lastIndexOf('::');
      const pagename = dataLayerJson.pageName.substring(0, lastIndex);
      const brandName = window.location.pathname?.split('/')?.filter(Boolean).pop()?.split('.html')[0];

      const highlightMedium = this.componentHost.querySelectorAll('.ace-highlight.ace-highlight_mediumlink');
      const highlightLarge = this.componentHost.querySelectorAll('.ace-highlight.ace-highlight--large');
      highlightMedium?.forEach((hmb) => {
        hmb?.querySelector('.cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {

          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: `${pagename || ''}::${brandName || ''}`,
              bloc_name: 'brand',
              bloc_interaction: 'brand redirection',
              bloc_type: `${brandName || ''}`
            }
          };
          window.dataLayer.push({
            'event_data': null
          });
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      });
      highlightLarge?.forEach((hlb) => {
        if (hlb.closest('.experiencefragment')) {
          hlb?.querySelectorAll('.cmp-button')?.forEach((button, index) => {
            if (index === 0) {
              button?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
                const datasEvent = {
                  event: 'GA4event',
                  eventName: 'enroll_interact',
                  event_data: {
                    pagename: `${pagename || ''}::${brandName || ''}`,
                    cta_name: 'enroll',
                    enroll_context: 'brand corner'
                  }
                };
                window.dataLayer.push({
                  'event_data': null
                });
                // eslint-disable-next-line no-undef
                TagManager.trackEvent(datasEvent);
              });
            } else {
              button?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
                const datasEvent = {
                  event: 'GA4event',
                  eventName: 'enroll_interact',
                  event_data: {
                    pagename: `${pagename || ''}::${brandName || ''}`,
                    cta_name: 'discover program',
                    enroll_context: 'brand corner'
                  }
                };
                window.dataLayer.push({
                  'event_data': null
                });
                // eslint-disable-next-line no-undef
                TagManager.trackEvent(datasEvent);
              });
            }
          });
        } else {
          hlb?.querySelector('.cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `${pagename || ''}::${brandName || ''}`,
                bloc_name: 'flagship hotel block',
                bloc_interaction: 'push flagship hotel',
                bloc_type: `${e.target.closest('.ace-highlight__content')?.querySelector('.ace-highlight__content--title').innerHTML.trim().replace(/&nbsp;/g, '') || ''}`
              }
            };
            window.dataLayer.push({
              'event_data': null
            });
            // eslint-disable-next-line no-undef
            TagManager.trackEvent(datasEvent);
          });
        }
      });
    };
    highLightBlock();
    const headingHeroBlock = () => {
      const lastIndex = dataLayerJson.pageName.lastIndexOf('::');
      const pagename = dataLayerJson.pageName.substring(0, lastIndex);
      const brandName = window.location.pathname?.split('/')?.filter(Boolean).pop()?.split('.html')[0];

      const headingHeroButton = this.componentHost.querySelectorAll('.ace-headingpagehero');
      headingHeroButton?.forEach((hhb) => {
        hhb?.querySelector('.cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: `${pagename || ''}::${brandName || ''}`,
              bloc_name: 'banner cta',
              bloc_interaction: 'brand redirection',
              bloc_type: `${brandName || ''}`
            }
          };
          window.dataLayer.push({
            'event_data': null
          });
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      });
    };
    headingHeroBlock();
    const calloutBlock = () => {
      const lastIndex = dataLayerJson.pageName.lastIndexOf('::');
      const pagename = dataLayerJson.pageName.substring(0, lastIndex);
      const brandName = window.location.pathname?.split('/')?.filter(Boolean).pop()?.split('.html')[0];

      const brandCornerDetailTemplate = this.componentHost.querySelectorAll('.brand-corner-detail-page-template');
      const aceCallouts = this.componentHost.querySelectorAll('.ace-callout-container');

      aceCallouts?.forEach((aceContainer, index) => {
        if (index === 0) {
          aceContainer?.querySelectorAll('.ace-callout-section li a')?.forEach((item) => {
            const name = aceContainer?.querySelector('.cmp-teaser__pretitle a')?.textContent;

            const datasEventBrand = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `${pagename || ''}::${brandName || ''}`,
                bloc_name: 'hotel',
                bloc_interaction: 'push hotel',
                bloc_type: `${name?.trim() || ''}`
              }
            }


            item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
              const interactionBlock = `${ee.target.querySelector('.cmp-button__text')?.innerText.trim().replace(/&nbsp;/g, '') || ''}`;
              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                  bloc_name: `${ee.target.closest('.cmp-teaser__content')?.querySelector('.cmp-teaser__pretitle')?.innerText.trim().replace(/&nbsp;/g, '') || ''}`,
                  bloc_interaction: `${thematicPage === 'thematic' ? 'click' : interactionBlock}`,
                  bloc_type: `${thematicPage === 'thematic' ? 'thematic_bloc::hotel' : 'click on cta'}`,
                  bloc_context: 'activities and events'
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(brandCornerDetailTemplate ? datasEventBrand : datasEvent);
            });
          });
        } else if (index === 1) {
          aceContainer?.querySelectorAll('.ace-carousel__content li a')?.forEach((item) => {
            const name = aceContainer?.querySelector('.cmp-teaser__pretitle a')?.textContent;

            const datasEventBrand = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `${pagename || ''}::${brandName || ''}`,
                bloc_name: 'hotel',
                bloc_interaction: 'push hotel',
                bloc_type: `${name?.trim() || ''}`
              }
            }


            item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
              const interactionBlock = `${ee.target.querySelector('.cmp-button__text')?.innerText.trim().replace(/&nbsp;/g, '') || ''}`;
              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                  bloc_name: `${ee.target.closest('.cmp-teaser__content')?.querySelector('.cmp-teaser__pretitle')?.innerText.trim().replace(/&nbsp;/g, '') || 'best of your activities'}`,
                  bloc_interaction: `${thematicPage === 'thematic' ? 'click' : interactionBlock}`,
                  bloc_type: `${thematicPage === 'thematic' ? 'thematic_bloc::magazine' : 'click on cta'}`,
                  bloc_context: 'activities and events'
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(brandCornerDetailTemplate ? datasEventBrand : datasEvent);
            });
          });
          aceContainer?.querySelectorAll('.ace-carousel-version-button a')?.forEach((item) => {
            const name = aceContainer?.querySelector('.cmp-teaser__pretitle a')?.textContent;

            const datasEventBrand = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `${pagename || ''}::${brandName || ''}`,
                bloc_name: 'hotel',
                bloc_interaction: 'push hotel',
                bloc_type: `${name?.trim() || ''}`
              }
            }


            item?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                  bloc_name: 'best of your activities',
                  bloc_interaction: 'see all activities',
                  bloc_type: 'click on cta',
                  bloc_context: 'activities and events'
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(brandCornerDetailTemplate ? datasEventBrand : datasEvent);
            });
          });
        } else if (index === 2) {
          if (thematicPage === 'thematic') {
            aceContainer?.querySelectorAll('.callout-section li a')?.forEach((item) => {
              const name = aceContainer?.querySelector('.cmp-teaser__pretitle a')?.textContent;

              const datasEventBrand = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${pagename || ''}::${brandName || ''}`,
                  bloc_name: 'hotel',
                  bloc_interaction: 'push hotel',
                  bloc_type: `${name?.trim() || ''}`
                }
              }


              item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
                const datasEvent = {
                  event: 'GA4event',
                  eventName: 'bloc_interact',
                  event_data: {
                    pagename: dataLayerJson.pageName,
                    bloc_name: `${ee.target.closest('.cmp-teaser__content')?.querySelector('.cmp-teaser__pretitle')?.innerText.trim().replace(/&nbsp;/g, '') || 'best of your activities'}`,
                    bloc_interaction: 'click',
                    bloc_type: 'thematic_bloc::brands'
                  }
                };
                window.dataLayer.push({
                  'event_data': null
                });
                // eslint-disable-next-line no-undef
                TagManager.trackEvent(brandCornerDetailTemplate ? datasEventBrand : datasEvent);
              });
            });
          } else {
            aceContainer?.querySelectorAll('.ace-callout-section li a')?.forEach((item) => {
              const name = aceContainer?.querySelector('.cmp-teaser__pretitle a')?.textContent;

              const datasEventBrand = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${pagename || ''}::${brandName || ''}`,
                  bloc_name: 'hotel',
                  bloc_interaction: 'push hotel',
                  bloc_type: `${name?.trim() || ''}`
                }
              }


              item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
                const datasEvent = {
                  event: 'GA4event',
                  eventName: 'bloc_interact',
                  event_data: {
                    pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                    bloc_name: 'browse our activities',
                    bloc_interaction: `${ee.target.closest('.cmp-teaser__content')?.querySelector('.cmp-teaser__pretitle')?.innerText.trim().replace(/&nbsp;/g, '') || 'click'}`,
                    bloc_type: `${thematicPage === 'thematic' ? 'thematic_bloc::brands' : 'click on cta'}`,
                    bloc_context: 'activities and events'
                  }
                };
                window.dataLayer.push({
                  'event_data': null
                });
                // eslint-disable-next-line no-undef
                TagManager.trackEvent(brandCornerDetailTemplate ? datasEventBrand : datasEvent);
              });
            });
          }
        } else if (index === 3) {
          aceContainer?.querySelectorAll('.ace-callout-section li a')?.forEach((item) => {
            const name = aceContainer?.querySelector('.cmp-teaser__pretitle a')?.textContent;

            const datasEventBrand = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `${pagename || ''}::${brandName || ''}`,
                bloc_name: 'hotel',
                bloc_interaction: 'push hotel',
                bloc_type: `${name?.trim() || ''}`
              }
            }


            item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                  bloc_name: 'unforgettable memories',
                  bloc_interaction: `${ee.target.querySelector('.cmp-button__text')?.innerText.trim().replace(/&nbsp;/g, '') || 'click'}`,
                  bloc_type: 'click on cta',
                  bloc_context: 'activities and events'
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(brandCornerDetailTemplate ? datasEventBrand : datasEvent);
            });
          });
        }
      });
    };
    calloutBlock();


    const portfolioBlock = () => {
        const lastIndex = dataLayerJson.pageName.lastIndexOf('::');
        const pagename = dataLayerJson.pageName.substring(0, lastIndex);
        const urlBrandName = window.location.pathname?.split('/')?.filter(Boolean).pop()?.split('.html')[0];

        const brandItems = this.componentHost.querySelectorAll('.brandcorner_portfolio .brands__cards .brands__item');
        brandItems?.forEach((item) => {
          const brand = item.getAttribute('data-brand');
          const link = item.querySelector('.brands__item-link');
          const url = link?.getAttribute('href');

          if (link) {
            link.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
              event.preventDefault();

              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${pagename || ''}::${urlBrandName || ''}`,
                  bloc_name: 'brand block',
                  bloc_interaction: 'discover brand',
                  bloc_type: `${brand || ''}`
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(datasEvent);

              window.open(url)
            })
          }
        })
      }
      portfolioBlock();
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, LpActivityEvent, true);
